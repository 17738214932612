import { GoogleOAuthProvider, TokenResponse, googleLogout, useGoogleLogin } from "@react-oauth/google"
import { ReactNode, createContext, useContext, useEffect, useState } from "react"
import { toast } from "sonner"
import { useSessionStorage } from "usehooks-ts"
import { useClientConfig } from "./client-config-context"
import { Skeleton } from "@/components/ui/skeleton"

interface Credentials extends Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'> {
  expires?: number
}

interface UserInfo {
  id: string
  email: string
  verified_email: boolean
  name: string
  given_name: string
  family_name: string
  picture: string // url
  locale: string
}

interface Profile {
  role: string
  userinfo: UserInfo
  authorization: string
  expires: number
}

interface Context {
  profile: Profile|undefined
  login: ()=>void
  logout: ()=>void
  loading: boolean
}
const UserLoginContext = createContext({
  profile: undefined,
  login: ()=>toast('Login not implemented'),
  logout: ()=>toast('Logout not implemented'),
  loading: false,
} as Context)

const fetchUserProfile = (creds:Credentials, setProfile:(profile:Profile)=>void) => {
  const headers = {
    Authorization: `Bearer ${creds?.access_token}`,
    Accept: 'application/json',
  }

  return fetch('/api/v1/userprofile', {headers})
  .then((res) => {
    if (!res.ok) throw new Error(`${res.status} ${res.statusText}`)
    return res.json()
  })
  .then(setProfile)
  .catch((e)=>{
    console.error(e)
  })
}

interface Props {
  children: ReactNode
}
const Inner = ({children}:Props) => {
  const [creds, setCreds, removeCreds] = useSessionStorage<Credentials|undefined>('creds', undefined)

  const [profile, setProfile] = useState<Profile>()
  const [loading, setLoading] = useState(false)

  const logout = () => {
    removeCreds()
    setProfile(undefined)
    googleLogout()
  }

  const login = useGoogleLogin({
    onSuccess:(creds)=>{
      console.log('creds', creds)
      setCreds({...creds, expires: Date.now() + (creds.expires_in * 1000)})
    },
    onError:(err)=>{
      console.log('err', err)
      toast.error('Sign in failed')
    }
  })

  useEffect(()=>{
    if (creds) {
      if ((creds.expires || 0) < Date.now()) {
        removeCreds()
        return
      }
      setLoading(true)
      fetchUserProfile(creds, setProfile)
      .catch(err=>toast.error(err))
      .finally(()=>setLoading(false))
    }
  }, [creds, removeCreds])

  useEffect(()=>{
    // refresh user profile upon expiration
    if (creds && profile) {
      const fiveMinutes = 5 * 60 * 1000
      const tillExp = profile.expires ? profile.expires * 1000 - Date.now() : fiveMinutes
      if (tillExp <= 0) console.warn('Authorization has bad expiration')
      const to = tillExp <= 0 ? undefined : setTimeout(()=>{
        setLoading(true)
        fetchUserProfile(creds, setProfile)
        .catch(err=>toast.error(err))
        .finally(()=>setLoading(false))
      }, tillExp)
      return ()=>clearTimeout(to)
    }
  }, [creds, profile])

  const value:Context = { profile, login, logout, loading }
  return (
    <UserLoginContext.Provider value={value}>{children}</UserLoginContext.Provider>
  )
}

export const UserLoginProvider = ({children}:Props) => {
  const { clientId } = useClientConfig()
  if (!clientId) return <Skeleton/>
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Inner>{children}</Inner>
    </GoogleOAuthProvider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useUserProfile = () => useContext(UserLoginContext)